import React, { useEffect, useState } from "react";
import Logo from "../../Assest/images/logo.gif";
import "./style.css";
import { Outlet } from "react-router-dom";
import { SetLoginModal, checklogin } from "../../Store/Reducer/config";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd";
import { googleLogout } from "@react-oauth/google";

const Navbar = () => {
  const { is_login, user } = useSelector((state) => state.config);
  const Dispatch = useDispatch();
  const [Menu, setMenu] = useState(false);
  const LogoutHandler = () => {
    localStorage.clear("DL_credential");
    localStorage.clear("DL_access_token");
    Dispatch(
      checklogin({
        is_login: false,
        name: "",
        email: "",
        profile: "",
      })
    );
    googleLogout();
  };
  const Navlink = [
    {
      name: "Products",
      link: "",
      childrens: [
        { name: "DLearners Parent", url: "dlearners-parent" },
        { name: "DLearners VAKT", url: "VAKT-product" },
        { name: "DLearners Learning Kit", url: "learningkit" },
      ],
    },
    {
      name: "Platforms",
      link: "",
      childrens: [
        { name: "Special Educator Engagement Platform", url: "seep" },
        { name: "For Schools", url: "school" },
      ],
    },
    {
      name: "Company",
      link: "",
      childrens: [
        { name: "About Us", url: "about" },
        { name: "People of DLearners", url: "peopleOfDLearners" },
        { name: "Grants", url: "about#grants" },
        { name: "Press", url: "about#press" },
        { name: "Careers", url: "careers" },
        { name: "Contact Us", url: "index#test" },
      ],
    },
  ];
  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-start cursor-default ">
          <img
            src={user?.profile}
            alt={user?.name}
            className="w-[50px] h-[50px] rounded-full overflow-hidden"
          />
          <div className="ml-2">
            <p className="font-semibold text-[18px]">{user?.name}</p>
            <p className="font-semibold text-[12px]">{user?.email}</p>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className="flex items-center py-2 text-[tomato] w-full"
          onClick={LogoutHandler}
        >
          <i class="fa-solid fa-right-from-bracket text-[22px] mr-2"></i>{" "}
          <p className="text-[16px] font-semibold">Logout</p>
        </button>
      ),
    },
  ];

  useEffect(() => {
    return () => {
      setMenu(false);
    };
  }, []);

  return (
    <div className="Navbar ">
      <div className="h-[50px] bg-DLlight flex items-center fixed top-0 left-0 z-[3] w-[100%] border-b-2">
        <a
          href="https://dlearners.com"
          className="h-full  max-[500px]:w-[150px] max-[500px]:h-[50px] max-[350px]:w-[70px] max-[350px]:h-[30px]"
        >
          <img src={Logo} alt="" className="h-full w-full" />
        </a>
        <div className="ml-3 max-[1100px]:hidden">
          <ul className="flex">
            <li className="mx-4">
              <a href="https://dlearners.com/home.html">Home</a>
            </li>
            <li className="mx-4">
              <a href="https://dlearners.com/explore">Explore</a>
            </li>
            {Navlink?.map((item, index) => {
              return (
                <li key={index} className="mx-4 dropdown-wrapper">
                  {item?.link != "" ? (
                    ""
                  ) : (
                    <button>
                      {item?.name}{" "}
                      <i className="fa-solid fa-caret-down ml-2"></i>
                    </button>
                  )}

                  <div className="dropdown-list">
                    <ul className="py-2">
                      {item?.childrens?.map((item2, index2) => {
                        return (
                          <li
                            key={index2}
                            className="font-semibold text-[14px] px-3 py-1 hover:bg-[#0002]"
                          >
                            <a href={"https://dlearners.com/" + item2?.url}>
                              {item2?.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              );
            })}
            <li className="mx-4">
              <a href="https://dlearners.com/events.html">Events</a>
            </li>
            <li className="mx-4">
              <a href="https://dlearners.com/faq.html">FAQ</a>
            </li>
            <li className="mx-4">
              <a href="https://dlearners.com/blog">Blogs</a>
            </li>
          </ul>
        </div>

        {!is_login ? (
          <div className="ml-auto pr-5">
            <button
              className="border-2 px-5 py-1 rounded-full mx-2 text-DLblue font-semibold border-DLblue max-[500px]:text-[12px] max-[500px]:px-3"
              onClick={() => Dispatch(SetLoginModal(true))}
            >
              Log in
            </button>
            <button
              className="border-2 px-5 py-1 rounded-full mx-2 text-DLlight font-semibold border-DLblue bg-DLblue max-[500px]:text-[12px] max-[500px]:px-3"
              onClick={() => Dispatch(SetLoginModal(true))}
            >
              Sign up
            </button>
          </div>
        ) : (
          <div className="ml-auto pr-5">
            <Dropdown
              menu={{
                items,
              }}
            >
              <button
                title={user?.name}
                className="w-[40px] h-[40px] rounded-full overflow-hidden"
              >
                <img src={user?.profile} alt={user?.name} />
              </button>
            </Dropdown>
          </div>
        )}

        <button
          onClick={() => setMenu(!Menu)}
          className="mr-2 text-[26px] min-[1100px]:hidden"
        >
          <i className="fa-solid fa-bars"></i>
        </button>

        {Menu && (
          <div className="w-full absolute top-[100%]  bg-[#fff] border-b-4 pb-3">
            <ul className="flex flex-col">
              <li className="mx-4 my-2">
                <a href="https://dlearners.com/home.html">Home</a>
              </li>
              <li className="mx-4 my-2">
                <a href="https://dlearners.com/explore">Explore</a>
              </li>

              {Navlink?.map((item, index) => {
                return (
                  <li key={index} className="mx-4 dropdown-wrapper my-2">
                    {item?.link != "" ? (
                      ""
                    ) : (
                      <button>
                        {item?.name}{" "}
                        <i className="fa-solid fa-caret-down ml-2"></i>
                      </button>
                    )}

                    <div className="dropdown-list flex-col w-[100%]">
                      <ul className="py-2">
                        {item?.childrens?.map((item2, index2) => {
                          return (
                            <li
                              key={index2}
                              className="font-semibold text-[14px] px-3 py-1 hover:bg-[#0002]"
                            >
                              <a href={"https://dlearners.com/" + item2?.url}>
                                {item2?.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
              <li className="mx-4 my-2">
                <a href="https://dlearners.com/events">Events</a>
              </li>
              <li className="mx-4 my-2">
                <a href="https://dlearners.com/faq">FAQ</a>
              </li>
              <li className="mx-4 my-2">
                <a href="https://dlearners.com/blog">Blogs</a>
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="mt-[50px] ">
        <Outlet />
      </div>
    </div>
  );
};

export default Navbar;
